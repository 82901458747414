import React, { useState, useEffect } from "react";
import { Modal } from "react-responsive-modal";
import {
  getHistoryListByGroup,
  getGroupDetailById,
  updateGroupById,
  getChannel,
  deleteGroupById,
} from "./broadcastAPI";
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";
import {
  EditOutlined,
  CloseOutlined,
  SaveOutlined,
  DeleteOutlined,
} from "@ant-design/icons";
import { composeMessageForGroup, getGroupListData } from "./broadcastAPI";
import { Pagination } from "antd";

const BroadcastHome = (props) => {
  const {
    selectedTab,
    addShareContentType,
    selectedGroupId,
    setGroupList,
    setSelectedGroupId,
  } = props;
  const botType = localStorage.getItem("botType");

  // popup modal state management
  const [deleteOpen, setDeleteOpen] = useState(false);
  const [addMember, setAddMember] = useState(false);
  // add member popup
  const onAddMemberOpenModal = () => setAddMember(true);
  const onAddMemberCloseModal = () => {
    setEditMemberData(false);
    setAddMember(false);
  };
  // delete group popup
  const onDeleteOpenModal = () => setDeleteOpen(true);
  const onDeleteCloseModal = () => setDeleteOpen(false);

  const [addMemberData, setAddMemberData] = useState({});
  const [editGroupname, setEditGroupName] = useState(false);
  const [editMemberData, setEditMemberData] = useState(false);
  const [composeMessageList, setComposeMessageList] = useState([]);
  const [historyList, setHistoryList] = useState([]);
  const [groupDetailList, setGroupDetailList] = useState({});
  const [channelList, setChannelList] = useState([]);
  const [selectedChannel, setSelectedChannel] = useState([]);
  const [channelBot, setChannelBot] = useState("");
  const [pageNum, setPageNum] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [messages, setMessages] = useState([]);
  const [messageModalVisible, setMessageModalVisible] = useState(false);
  const handlePageChange = (page) => {
    console.log("the page is", page);
    console.log("the total page is", totalPages);
    setPageNum(page);
  };

  const getHistoryList = () => {
    console.log(selectedGroupId);
    getHistoryListByGroup(selectedGroupId).then((response) => {
      if (response?.response.length > 0) {
        console.log(response);
        setHistoryList([...response.response]);
      } else {
        setHistoryList([]);
      }
    });
  };

  const getGroupDetailList = () => {
    getGroupDetailById(selectedGroupId, pageNum).then((response) => {
      console.log(response, "group");
      if (response?.response) {
        setGroupDetailList({ ...response?.response });
        setTotalPages(response?.total_pages);
      } else {
        setGroupDetailList({});
      }
    });
    console.log(groupDetailList);
  };

  const getChannelList = () => {
    const queryParams = {
      botType: botType.split(","),
    };
    getChannel(queryParams)
      .then((response) => {
        console.log(response?.response?.channels, "channel");
        if (response?.response?.channels.length > 0) {
          setSelectedChannel(response?.response?.channels[0]);
          setChannelBot(response?.response?.channels[0].value);
          setChannelList([...response?.response?.channels]);
        } else {
          setChannelList([]);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    (selectedTab.type === "edit" && getGroupDetailList()) ||
      (selectedTab.type === "history" && getHistoryList()) ||
      (selectedTab.type === "share" && getChannelList());
  }, [selectedTab, pageNum]);

  useEffect(() => {
    if (addShareContentType?.type && composeMessageList.length < 5) {
      switch (addShareContentType.type) {
        case "media":
          composeMessageList.push({
            title: "Media",
            type: "file",
            fileName: "",
            value: "",
            file: "",
            fileType: "",
          });
          setComposeMessageList([...composeMessageList]);
          console.log(composeMessageList, "Media");
          break;
        case "message":
          composeMessageList.push({
            title: "Text Message",
            type: "textarea",
            value: "",
          });
          setComposeMessageList([...composeMessageList]);
          console.log(composeMessageList, "message");
          break;
      }
    }
  }, [addShareContentType]);

  const handleEditGroupNameInputChange = (event) => {
    groupDetailList.group_name = event.target.value;
    setGroupDetailList({ ...groupDetailList });
  };

  const handleEditGroupNameClick = () => {
    groupDetailList["originalGroupName"] = groupDetailList.group_name;
    setGroupDetailList({ ...groupDetailList });
    setEditGroupName(true);
  };

  const handleSaveGroupNameClick = () => {
    delete groupDetailList["originalGroupName"];
    const payload = {
      method: "update_group_details",
      name: groupDetailList.group_name,
    };
    updateGroupById(selectedGroupId, payload)
      .then((response) => {
        console.log(response, "grp name update");
        setGroupDetailList({ ...groupDetailList });
        setEditGroupName(false);
      })
      .catch((error) => {});
  };

  const handleCloseEditGroupNameClick = () => {
    groupDetailList.group_name = groupDetailList.originalGroupName;
    delete groupDetailList["originalGroupName"];
    setGroupDetailList({ ...groupDetailList });
    setEditGroupName(false);
  };

  const handleAddMemberClick = () => {
    onAddMemberOpenModal();
    addMemberData["group_name"] = groupDetailList?.group_name;
    setAddMemberData({ ...addMemberData });
  };

  const handleEditMemberClick = (index) => {
    addMemberData["group_name"] = groupDetailList?.group_name;
    addMemberData["memberName"] = groupDetailList?.user_details[index]?.Name;
    addMemberData["contactNumber"] =
      groupDetailList?.user_details[index]?.Contact;
    setAddMemberData({ ...addMemberData });
    console.log(addMemberData, "add member data");
    setEditMemberData(true);
    onAddMemberOpenModal();
  };

  const handleMemberDeleteClick = (index) => {
    // groupDetailList.user_details.splice(index, 1);
    const payload = {
      method: "delete",
      user_detail: {
        Name: groupDetailList.user_details[index]["Name"],
        Contact: groupDetailList.user_details[index]["Contact"],
      },
    };
    updateGroupById(selectedGroupId, payload)
      .then((response) => {
        console.log(response);
        getGroupDetailList();
      })
      .catch((error) => {});
    // setGroupDetailList({ ...groupDetailList });
  };

  const handleHistoryResendClick = () => {
    console.log("resend");
  };

  const handleRemoveMessage = (index) => {
    composeMessageList.splice(index, 1);
    setComposeMessageList([...composeMessageList]);
  };

  const handleDeleteGroup = () => {
    deleteGroupById(selectedGroupId).then((response) => {
      setSelectedGroupId("");
      setGroupDetailList([]);
      onDeleteCloseModal();
    });
    const queryParams = {
      botType: botType.split(","),
    };
    getGroupListData(queryParams).then((response) => {
      if (response?.length > 0) {
        setGroupList([...response]);
      } else {
        setGroupList([]);
      }
    });
  };

  const handleAddedMemberData = (event, valueName) => {
    switch (valueName) {
      case "groupName":
        addMemberData["group_name"] = event?.target?.value;
        break;
      case "memberName":
        addMemberData["memberName"] = event?.target?.value;
        break;
      case "contactNumber":
        addMemberData["contactNumber"] = event?.target?.value;
        break;
    }
    setAddMemberData({ ...addMemberData });
  };

  const saveAddedMember = () => {
    const payload = {
      method: "add",
      user_detail: {
        Name: addMemberData["memberName"],
        Contact: addMemberData["contactNumber"],
      },
    };
    updateGroupById(selectedGroupId, payload)
      .then((response) => {
        console.log(response);
        getGroupDetailList();
      })
      .catch((error) => {
        NotificationManager.error("Error updating group details");
      });
    setEditMemberData(false);
    setAddMemberData({});
    setAddMember(false);
    console.log(addMemberData, "add member data");
  };

  const handleChannelChange = (event) => {
    setChannelBot(event.target.value);
  };

  const sendMessage = (message) => {
    console.log("thissssssssssss", channelBot);
    const payload = {
      body: message,
      botType: channelBot,
    };
    console.log(payload);
    composeMessageForGroup(selectedGroupId, payload)
      .then((response) => {
        NotificationManager.success(
          "Message sent to WhatsApp. It will be delivered in a moment."
        );
        console.log(response);
      })
      .catch((error) => {
        NotificationManager.error("Error sending. Please try again.");
        console.log(error);
      });
    setComposeMessageList([]);
  };

  return (
    <div>
      {(selectedTab.type === "edit" &&
        (groupDetailList?.user_details?.length > 0 ? (
          <div className="mt-2">
            <div className="d-flex w-100 justify-content-between">
              <div className="group-detail-title">
                {editGroupname ? (
                  <input
                    className="input"
                    type="text"
                    value={groupDetailList.group_name}
                    id="group"
                    onChange={(event) => handleEditGroupNameInputChange(event)}
                  />
                ) : (
                  <span>{groupDetailList.group_name}</span>
                )}
                {editGroupname ? (
                  <span
                    className="pointer fs-18 p-1 mx-2"
                    style={{
                      color: "white",
                      backgroundColor: "#627DF1",
                      width: "25px",
                      borderRadius: "4px",
                      height: "25px",
                    }}
                    onClick={() => handleSaveGroupNameClick()}
                  >
                    <SaveOutlined />
                  </span>
                ) : (
                  <span
                    className="pointer fs-18 pl-1 mx-2 align-items-center justify-content-center"
                    style={{
                      color: "white",
                      backgroundColor: "#627DF1",
                      width: "25px",
                      borderRadius: "4px",
                      height: "25px",
                      display: "inline-block",
                      paddingBottom: "30px",
                    }}
                    onClick={() => handleEditGroupNameClick()}
                  >
                    <EditOutlined />
                  </span>
                )}
                {editGroupname && (
                  <span
                    className="pointer fs-18 text-danger"
                    onClick={() => handleCloseEditGroupNameClick()}
                  >
                    <CloseOutlined />
                  </span>
                )}
              </div>
              <div>
                <button
                  className="add-member-btn"
                  onClick={() => handleAddMemberClick()}
                >
                  <span className="add-member-text">Add Member</span>
                </button>
              </div>
            </div>

            <div className="mt-3">
              <div className="row table-title mb-2">
                <div className="col-md-2">SL No.</div>
                <div className="col-md-3">Name</div>
                <div className="col-md-3">Contact Number</div>
                <div className="col-md-4">
                  <input
                    className="input"
                    type="text"
                    placeholder="Search group member"
                  ></input>
                </div>
              </div>
              <hr className="m-0 nav-hr"></hr>
              <div className="group-detail-wrapper">
                {groupDetailList.user_details.map((group, index) => (
                  <div key={index}>
                    <div className="row table-content mt-2 mb-2">
                      <div className="col-md-2 ml-2">{index + 1}</div>
                      <div className="col-md-3">{group.Name}</div>
                      <div className="col-md-3">{group.Contact}</div>
                      <div
                        className="col-md-2 pointer fs-18 justify-content-center"
                        onClick={() => handleEditMemberClick(index)}
                      >
                        <button
                          className="btn d-flex justify-content-center align-items-center"
                          style={{
                            color: "white",
                            backgroundColor: "#636CDA",
                            width: "30px",
                            borderRadius: "4px",
                            height: "30px",
                            borderColor: "white",
                          }}
                        >
                          <EditOutlined />
                        </button>
                      </div>
                      <div
                        className="col-md-1 pointer fs-18 text-danger"
                        onClick={() => handleMemberDeleteClick(index)}
                      >
                        <DeleteOutlined />
                      </div>
                    </div>
                    <hr className="m-0 nav-hr"></hr>
                  </div>
                ))}
              </div>
            </div>
            <div className="text-center mt-5">
              <Pagination
                onChange={handlePageChange}
                current={pageNum}
                pageSize={10}
                total={totalPages * 10}
                showSizeChanger={false}
              />
            </div>

            <div className="text-center mt-5">
              <button className="delete-group-btn">
                <div
                  onClick={() => onDeleteOpenModal()}
                  className="delete-group-text"
                >
                  Delete Group
                </div>
              </button>
            </div>
          </div>
        ) : (
          <div className="no-data-container">
            <div className="no-data-content">
              No Data Available for selected Group
            </div>
          </div>
        ))) ||
        (selectedTab.type === "history" &&
          (historyList?.length > 0 ? (
            <div>
              <div className="text-left mt-2 group-detail-title">History</div>

              <div className="mt-3">
                <div className="row table-title mb-2">
                  <div className="col-md-1">SL No.</div>
                  <div className="col-md-3">ID</div>
                  <div className="col-md-2">Date Sent</div>
                  <div className="col-md-2">Channel</div>
                  <div className="col-md-2">View Message</div>
                  <div className="col-md-1">Status</div>
                  {/* <div className="col-md-3">
                    <input
                      className="input"
                      type="text"
                      placeholder="Search in history"
                    ></input>
                  </div> */}
                </div>
                <hr className="m-0 nav-hr"></hr>
                <div className="group-detail-wrapper history-wrapper">
                  {historyList.map((history, index) => (
                    <div key={index}>
                      <div className="row table-content mt-2 mb-2">
                        <div className="col-md-1">{index + 1}</div>
                        <div className="col-md-3">{history.group_id}</div>
                        <div className="col-md-2">{history.date}</div>
                        <div className="col-md-2">{history.channel}</div>
                        <div className="col-md-2">
                          <button
                            className="add-member-btn"
                            onClick={() => {
                              setMessages(history.messages);
                              setMessageModalVisible(true);
                            }}
                          >
                            <span className="add-member-text">
                              View Message
                            </span>
                          </button>
                        </div>
                        <div className="col-md-2">{history.status}</div>
                        <div className="col-md-2">
                          {history.status.toLowerCase() === "success" ? (
                            <div>
                              {/* <button className='add-member-btn' onClick={() => handleHistoryResendClick()}>
                                                                <span className='add-member-text'>
                                                                    Resend
                                                                </span>
                                                            </button> */}
                            </div>
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                      <hr className="m-0 nav-hr"></hr>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          ) : (
            <div className="no-data-container full-height">
              <div className="no-data-content">
                No Data Available {selectedTab.type}
              </div>
            </div>
          ))) ||
        (selectedTab.type === "share" && (
          <div>
            <div className="text-left mt-2 group-detail-title">
              COMPOSE MESSAGE
            </div>
            <div className="message-box">
              {composeMessageList?.length > 0 ? (
                <div>
                  {composeMessageList.map((message, i) => (
                    <div key={i} className="mt-1">
                      <div className="d-flex justify-content-between w-100">
                        <div className="share-title fs-14">{message.title}</div>
                        <div
                          className="pointer fw-900 pr-2"
                          onClick={() => handleRemoveMessage(i)}
                        >
                          x
                        </div>
                      </div>
                      <div>
                        {(message.type === "file" && (
                          <div className="media-wrapper p-2">
                            {/* <label htmlFor="fileInput" className="browse-button mb-0">
                                                                Browse
                                                                <input className = 'input'
                                                                    id={`fileInput-${i}`}
                                                                    type="file"
                                                                    name="media"
                                                                    style={{
                                                                        display: 'none',
                                                                    }}
                                                                    // onChange={()=>handleFileChange(i)}
                                                                    onChange={(event) => {
                                                                        const file = event.target.files[0];
                                                                        if (file) {
                                                                            console.log(file)
                                                                            const reader = new FileReader();
                                                                            reader.onload = () => {
                                                                                const base64File = reader.result
                                                                                
                                                                                // Update composeMessageList inside the onload event
                                                                                composeMessageList[i].value = file;
                                                                                composeMessageList[i].fileName = file.name;
                                                                                composeMessageList[i].file = base64File;
                                                                                composeMessageList[i].fileType = file?.type
                                                                                // Update the state after setting values
                                                                                setComposeMessageList([...composeMessageList]);
                                                                            };
                                                                            
                                                                            reader.readAsDataURL(file);
                                                                        }
                                                                    }}
                                                                    
                                                                />
                                                            </label> */}
                            <input
                              className="input"
                              id={`fileInput-${i}`} // Use a unique ID for each file input
                              type="file"
                              name="media"
                              style={{
                                display: "none",
                              }}
                              onChange={(event) => {
                                const file = event.target.files[0];
                                if (file) {
                                  const reader = new FileReader();
                                  reader.onload = () => {
                                    const base64File = reader.result;

                                    // Create a copy of composeMessageList
                                    const updatedComposeMessageList = [
                                      ...composeMessageList,
                                    ];

                                    // Update the specific message object based on index (i)
                                    updatedComposeMessageList[i].value = file;
                                    updatedComposeMessageList[i].fileName =
                                      file.name;
                                    updatedComposeMessageList[i].file =
                                      base64File;
                                    updatedComposeMessageList[i].fileType =
                                      file?.type;

                                    // Update the state with the modified composeMessageList
                                    setComposeMessageList(
                                      updatedComposeMessageList
                                    );
                                  };

                                  reader.readAsDataURL(file);
                                }
                              }}
                            />

                            <label
                              htmlFor={`fileInput-${i}`}
                              className="browse-button mb-0"
                            >
                              Browse
                            </label>

                            {message.fileName && (
                              <span className="fs-14 fw-500 mt-1">
                                {message.fileName}
                              </span>
                            )}
                          </div>
                        )) ||
                          (message.type === "textarea" && (
                            <div>
                              <textarea
                                className="w-100"
                                rows={10}
                                name="textMessage"
                                onChange={(e) => {
                                  composeMessageList[i].value = e.target.value;
                                  setComposeMessageList(composeMessageList);
                                }}
                              ></textarea>
                            </div>
                          ))}
                      </div>
                    </div>
                  ))}
                </div>
              ) : (
                <div className="no-data-container">
                  <div className="no-data-content">No Composed Message</div>
                </div>
              )}
            </div>
            {composeMessageList && (
              <span
                className={` ${
                  composeMessageList.length === 5 && "font-red fw-900 fs-14"
                } add-group-note pl-1`}
              >
                Note: You can add maximum 5 messages
              </span>
            )}
            <div className="d-flex justify-content-between w-100 pl-3 pr-3">
              <div>
                <div className="share-title">Channel</div>
                <select onChange={handleChannelChange}>
                  <option value="">Please select an option</option>
                  {channelList.map((channel, index) => (
                    <option value={channel.botType} key={index}>
                      {channel.channel_name}
                    </option>
                  ))}
                </select>
              </div>
              <div className="">
                <button
                  className="send-btn mt-3"
                  onClick={() => sendMessage(composeMessageList)}
                >
                  Send
                </button>
              </div>
            </div>
          </div>
        ))}
      <NotificationContainer />
      {/* Delete Group popup model */}
      <Modal
        open={deleteOpen}
        onClose={onDeleteCloseModal}
        closeOnEsc={true}
        closeOnOverlayClick={false}
        showCloseIcon={false}
        center
      >
        <div className="p-2">
          <span className="fw-900">
            Are you sure you want to delete the group "
            {groupDetailList.group_name}"?
          </span>
          <hr className="nav-hr mt-3 mb-3"></hr>
          <div className="p-2 d-flex justify-content-between">
            <button
              className="btn btn-custom flex-1 ml-1 mr-1"
              onClick={() => onDeleteCloseModal()}
            >
              Cancel
            </button>
            <button
              className="btn btn-custom flex-1 ml-1 mr-1"
              onClick={() => handleDeleteGroup()}
            >
              Confirm
            </button>
          </div>
        </div>
      </Modal>

      {/* Message History popup model */}
      <Modal
        open={messageModalVisible}
        onClose={() => setMessageModalVisible(false)}
        closeOnEsc={true}
        closeOnOverlayClick={false}
        showCloseIcon={false}
        center
      >
        <div className="p-2">
          <span className="fw-900">Messages</span>
          <hr className="nav-hr mt-3 mb-3"></hr>
          <div className="my-2 h-50"
          style={{
            overflowY: "auto",
          }}
          >
            {messages.map((message, index) => (
              <div key={index} className="mt-1">
                <div className="d-flex justify-content-between w-100">
                  <div className="share-title fs-14">{message.title}</div>
                </div>
                {message.type === "textarea" && (
                  <>
                  <div className="message-text my-1 mx-1 p-2"
                  style={{
                    border: "1px solid #ccc",
                    borderRadius: "5px"
                  }}
                  >{message.value}</div>
                  <hr className="nav-hr mt-1 mb-1"></hr>
                  </>
                )}
                {message.type === "file" &&
                  (message.fileType === "image/jpeg" ||  message.fileType === "image/png" ||  message.fileType === "image/jpg") && (
                    <>
                    <div className="my-1 mx-1 p-2"
                    style={{
                      border: "1px solid #ccc",
                      borderRadius: "5px",
                    }}
                    >
                    <img
                      src={message.file}
                      alt={message.fileName}
                      className="image-preview my-1 mx-2"
                      width={520}
                      style={{
                        borderRadius: "5px",
                        objectFit: "contain",
                      }}
                    />
                    </div>
                    <hr className="nav-hr mt-1 mb-1"></hr>
                    </>
                  )}
                {message.type === "file" &&
                  message.fileType === "application/pdf" && (
                    <button className="my-1 mx-1" onClick={() => window.open(message.file, "_blank")}>
                      Open PDF
                    </button>
                  )}
              </div>
            ))}
          </div>
          <div className="p-2 d-flex justify-content-between">
            <button
              className="btn btn-custom flex-1 ml-1 mr-1"
              onClick={() => setMessageModalVisible(false)}
            >
              Close
            </button>
          </div>
        </div>
      </Modal>

      {/* add member popup model */}
      <Modal
        open={addMember}
        onClose={onAddMemberCloseModal}
        closeOnEsc={true}
        closeOnOverlayClick={false}
        showCloseIcon={false}
        center
        name="addMemberModel"
      >
        <div className="p-2">
          <div className="fs-18 fw-900 text-center">
            {editMemberData ? (
              <span>EDIT CONTACT LIST</span>
            ) : (
              <span>ADD CONTACT LIST</span>
            )}
          </div>
          <div className="form-group mt-3">
            <label className="fs-14 fw-600">Group Name</label>
            <input
              className="input"
              type="text"
              disabled
              name="groupName"
              value={addMemberData.group_name}
              onChange={(event) => handleAddedMemberData(event, "groupName")}
            ></input>
          </div>
          <div className="fs-14 fw-600">Add Member</div>
          <hr className="nav-hr mt-1 mb-2"></hr>
          <div className="form-group row">
            <div className="col-md-6">
              <label className="fs-14 fw-600">Name</label>
              <input
                className="input"
                type="text"
                name="name"
                value={addMemberData.memberName}
                onChange={(event) => handleAddedMemberData(event, "memberName")}
              ></input>
            </div>
            <div className="col-md-6">
              <label className="fs-14 fw-600">Contact number</label>
              <input
                className="input"
                type="tel"
                name="contactNumber"
                value={addMemberData.contactNumber}
                maxLength={12}
                onChange={(event) =>
                  handleAddedMemberData(event, "contactNumber")
                }
              ></input>
            </div>
          </div>
          <hr className="nav-hr mt-3 mb-3"></hr>
          <div className="p-2 d-flex justify-content-between">
            <button
              className="btn btn-custom flex-1 ml-1 mr-1"
              onClick={() => onAddMemberCloseModal()}
            >
              Cancel
            </button>
            <button
              className="btn btn-custom flex-1 ml-1 mr-1"
              onClick={() => saveAddedMember()}
            >
              {editMemberData ? "Edit" : "Add"}
            </button>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default BroadcastHome;
