import React, { useState } from 'react';
import DocumentList from './DocumentList';
import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import dp from "../../assets/img/dp_placeholder.png";
import Moment from 'react-moment';
import { faSearch, faTimes, faChevronLeft, faChevronDown } from '@fortawesome/free-solid-svg-icons';
import { Button, Form, Input, Modal, Select, Skeleton, notification, Space, Switch } from 'antd';
import { UserOutlined } from '@ant-design/icons';
import 'antd/dist/reset.css';
library.add(faSearch, faTimes, faChevronLeft, faChevronDown);

const ChatList = (props) => {

  const agentBots = localStorage.getItem('botType').split(",")
  const optionList = [{ label: 'WhatsApp', value: 'whatsapp' }, { label: 'Web Bot', value: 'webbot' }, { label: 'App Bot', value: 'appbot' }]
  if (agentBots.includes('112bc24aeea5105893748_Dev')) {
    optionList.push({ label: 'Support', value: 'supportbot' });
  }
  const selectedValuesArray = optionList.map(option => option.value);

  const [selectedValues, setSelectedValues] = React.useState(selectedValuesArray);
  const [channelBot, setChannelBot] = useState('')
  const { tab, hasJoined, showHideDocs, showSavedChats, selectChat, showDocuments, setTabs, showChatDetails, openDoc, showMore, conversation, conversations, search, onChangeHandler, savedChatsList, fetchConversationList, fetchSavedChats, deleteSavedChat, extractPhoneNo, initializeWp, setInitializeWp } = props;
  const [wpNumber, setWpNumber] = useState('')
  const [form] = Form.useForm();
  const [successConversationNotification, setSuccessConversionNotification] = notification.useNotification();
  // console.log("Init", initializeWp)
  const phoneNo = extractPhoneNo(conversation.convId);

  const openNotification = (placement) => {

    const config = {
      message: `Conversation Initiated successfully`,
      description:
        `Whats app conversation has successfully initiated. User will recive this message in 1 - 2 mintues.`,
      placement,
      duration: 0,
    }
    successConversationNotification.info(config);
  };

  const handleChannelChange = (value) => {
    setChannelBot(value)
  }


  const initiateConversation = () => {
    console.log(channelBot)
    global.socket1.emit("initiateWhatsAppConversation", {
      'phoneNumber': wpNumber,
      'botType': channelBot
    })
    setInitializeWp(false)
    setWpNumber('');
    openNotification('topRight')
  }

  const renderConversationsList = (conversations, selectedChat) => {
    if (selectedValues.length <= 0) {
      return (
        <div className='w-100 text-center fa-layers-text font-600'>
          No Chats Available
        </div>
      )
    } else {
      return conversations.filter((conversation) => {
        const query = search.toLowerCase();
        const name = (conversation?.userData?.name)?.toLowerCase();
        const userName = conversation.userData?.name?.toLowerCase();
        const phoneNumber = conversation.convId.slice(2);
        const lastTranscript = transcriptFormatter(conversation).toLowerCase();
        return ((name && name.includes(query)) || (lastTranscript && lastTranscript.includes(query)));
      })
        .sort((a, b) => {
          return new Date(b.lastUpdatedOn) - new Date(a.lastUpdatedOn);
        })
        .map((conversation, key) => {
          const lastTranscript = transcriptFormatter(conversation);
          if (conversation.channel === 'whatsapp' && selectedValues.includes('whatsapp') || conversation.channel === 'web' && selectedValues.includes('webbot') || conversation.channel === 'app' && selectedValues.includes('appbot') || conversation.channel === 'support' && selectedValues.includes('supportbot')) {
            return (

              <li key={key} onClick={() => { selectChat(conversation, "watch"); conversation.is_read = true; }} className={`${selectedChat.convId === conversation.convId ? 'active' : ''}`}>
                <a href="#" >
                  <div className="profile-image">
                    <img src={conversation.userData && conversation.userData.profile_pic ? conversation.userData.profile_pic : dp} alt="Profile picture" />
                  </div>
                  <div className="content">
                    {(conversation.userData &&
                      conversation.userData.name != undefined) ||
                      "" ||
                      null ? (
                      <span>
                        <strong>{conversation.userData.name}</strong>
                      </span>
                    ) : conversation.convId.split("-").length > 1 &&
                      conversation.convId.split("-")[2].charAt(0) === "4" ? (
                      <span>
                        <strong>
                          {conversation.name ? conversation.name : "User"}
                        </strong>
                      </span>
                    ) : isNaN(conversation.convId) ? (
                      <span>
                        <strong>
                          {conversation.name ? conversation.name : "User"}
                        </strong>
                      </span>
                    ) : (
                      <span>
                        <strong>
                          {conversation.convId
                            ? "+91 " + conversation.convId.slice(2)
                            : "User"}
                        </strong>
                      </span>
                    )}
                    {/* {conversation.convId.split("-").length > 1 &&
                conversation.convId.split("-")[2].charAt(0) === "4" ? (
                  <span>
                    <strong>
                      {conversation.name ? conversation.name : "User"}
                    </strong>
                  </span>
                ) : isNaN(conversation.convId) ? (
                  <span>
                    <strong>
                      {conversation.name ? conversation.name : "User"}
                    </strong>
                  </span>
                ) : (
                  <span>
                    <strong>
                      {conversation.convId
                        ? "+91 " + conversation.convId.slice(2)
                        : "User"}
                    </strong>
                  </span>
                )} */}
                    {conversation.channel == "web" ? (
                      <span>
                        <svg
                          style={{
                            marginLeft: "5px",
                            marginBottom: "3.5px",
                            color: "#ED0C6D",
                          }}
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          fill="currentColor"
                          className="bi bi-laptop"
                          viewBox="0 0 16 16"
                        >
                          <path d="M13.5 3a.5.5 0 0 1 .5.5V11H2V3.5a.5.5 0 0 1 .5-.5h11zm-11-1A1.5 1.5 0 0 0 1 3.5V12h14V3.5A1.5 1.5 0 0 0 13.5 2h-11zM0 12.5h16a1.5 1.5 0 0 1-1.5 1.5h-13A1.5 1.5 0 0 1 0 12.5z" />
                        </svg>
                      </span>
                    ) : // : isNaN(conversation.convId) ? (
                      //   <span>
                      //     <img
                      //       className="team-logo"
                      //       style={{
                      //         width: "16px",
                      //         marginLeft: "5px",
                      //         marginBottom: "3.5px",
                      //       }}
                      //       src="https://cdn.usbrandcolors.com/images/logos/slack-logo.png"
                      //       alt="Slack Logo"
                      //     />
                      //   </span>
                      // )
                      conversation.channel == "whatsapp" ? (
                        <span>
                          <svg
                            style={{
                              marginLeft: "5px",
                              marginBottom: "3.5px",
                              color: "#25D366",
                            }}
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            fill="currentColor"
                            className="bi bi-whatsapp"
                            viewBox="0 0 16 16"
                          >
                            <path d="M13.601 2.326A7.854 7.854 0 0 0 7.994 0C3.627 0 .068 3.558.064 7.926c0 1.399.366 2.76 1.057 3.965L0 16l4.204-1.102a7.933 7.933 0 0 0 3.79.965h.004c4.368 0 7.926-3.558 7.93-7.93A7.898 7.898 0 0 0 13.6 2.326zM7.994 14.521a6.573 6.573 0 0 1-3.356-.92l-.24-.144-2.494.654.666-2.433-.156-.251a6.56 6.56 0 0 1-1.007-3.505c0-3.626 2.957-6.584 6.591-6.584a6.56 6.56 0 0 1 4.66 1.931 6.557 6.557 0 0 1 1.928 4.66c-.004 3.639-2.961 6.592-6.592 6.592zm3.615-4.934c-.197-.099-1.17-.578-1.353-.646-.182-.065-.315-.099-.445.099-.133.197-.513.646-.627.775-.114.133-.232.148-.43.05-.197-.1-.836-.308-1.592-.985-.59-.525-.985-1.175-1.103-1.372-.114-.198-.011-.304.088-.403.087-.088.197-.232.296-.346.1-.114.133-.198.198-.33.065-.134.034-.248-.015-.347-.05-.099-.445-1.076-.612-1.47-.16-.389-.323-.335-.445-.34-.114-.007-.247-.007-.38-.007a.729.729 0 0 0-.529.247c-.182.198-.691.677-.691 1.654 0 .977.71 1.916.81 2.049.098.133 1.394 2.132 3.383 2.992.47.205.84.326 1.129.418.475.152.904.129 1.246.08.38-.058 1.171-.48 1.338-.943.164-.464.164-.86.114-.943-.049-.084-.182-.133-.38-.232z" />
                          </svg>
                        </span>
                      ) : conversation.channel == "support" ? (
                        <span>
                          <svg
                            style={{
                              marginLeft: "5px",
                              marginBottom: "3.5px",
                              color: "#ED0C6D",
                            }}
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            fill="currentColor"
                            className="bi bi-whatsapp"
                            viewBox="0 0 16 16"
                          >
                            <path d="M13.601 2.326A7.854 7.854 0 0 0 7.994 0C3.627 0 .068 3.558.064 7.926c0 1.399.366 2.76 1.057 3.965L0 16l4.204-1.102a7.933 7.933 0 0 0 3.79.965h.004c4.368 0 7.926-3.558 7.93-7.93A7.898 7.898 0 0 0 13.6 2.326zM7.994 14.521a6.573 6.573 0 0 1-3.356-.92l-.24-.144-2.494.654.666-2.433-.156-.251a6.56 6.56 0 0 1-1.007-3.505c0-3.626 2.957-6.584 6.591-6.584a6.56 6.56 0 0 1 4.66 1.931 6.557 6.557 0 0 1 1.928 4.66c-.004 3.639-2.961 6.592-6.592 6.592zm3.615-4.934c-.197-.099-1.17-.578-1.353-.646-.182-.065-.315-.099-.445.099-.133.197-.513.646-.627.775-.114.133-.232.148-.43.05-.197-.1-.836-.308-1.592-.985-.59-.525-.985-1.175-1.103-1.372-.114-.198-.011-.304.088-.403.087-.088.197-.232.296-.346.1-.114.133-.198.198-.33.065-.134.034-.248-.015-.347-.05-.099-.445-1.076-.612-1.47-.16-.389-.323-.335-.445-.34-.114-.007-.247-.007-.38-.007a.729.729 0 0 0-.529.247c-.182.198-.691.677-.691 1.654 0 .977.71 1.916.81 2.049.098.133 1.394 2.132 3.383 2.992.47.205.84.326 1.129.418.475.152.904.129 1.246.08.38-.058 1.171-.48 1.338-.943.164-.464.164-.86.114-.943-.049-.084-.182-.133-.38-.232z" />
                          </svg>
                        </span>)
                        : (
                          <svg style={{
                            marginLeft: "5px",
                            marginBottom: "3.5px",
                            color: "#ED0C6D",
                          }} width="16" fill="currentColor" height="16" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M15.5 1h-8C6.12 1 5 2.12 5 3.5v17C5 21.88 6.12 23 7.5 23h8c1.38 0 2.5-1.12 2.5-2.5v-17C18 2.12 16.88 1 15.5 1zm-4 21c-.83 0-1.5-.67-1.5-1.5s.67-1.5 1.5-1.5 1.5.67 1.5 1.5-.67 1.5-1.5 1.5zm4.5-4H7V4h9v14z" /></svg>
                        )}
                    {/* <span><strong>{conversation.name}</strong> srinath@colive.com</span> */}
                    {/* <span><strong>{conversation.name}</strong> srinath@colive.com</span> */}
                    <p>{lastTranscript}</p>
                  </div>
                  {!conversation.is_read &&
                    <div className='mr-4 unread-dot'>
                      .
                    </div>
                  }
                  <div className="timestamp d-flex justify-content-center flex-column">
                    <Moment format="MMM DD, YYYY">
                      {conversation.lastUpdatedOn}
                    </Moment>
                    <Moment format="hh:mm A">
                      {conversation.lastUpdatedOn}
                    </Moment>
                  </div>
                  {
                    showSavedChats ? (
                      <div className="action ml-2">
                        <FontAwesomeIcon icon={["fa", "times"]} onClick={(e) => { deleteSavedChat(e, conversation) }} />
                      </div>
                    ) : ('')
                  }

                </a>
              </li>
            )
          }
        })
    }
  }

  //Handle different formats of 'transcript' key in conversation object 
  const transcriptFormatter = (conversation) => {
    let lastTranscript = "";
    try {
      if (Array.isArray(conversation.lastTranscript)) {
        lastTranscript = conversation.lastTranscript[0].text;
      } else {
        lastTranscript = conversation.lastTranscript;
      }
    } catch {
      lastTranscript = ""
    }
    return lastTranscript;
  }


  const handleSelectChange = (values) => {
    setSelectedValues(values);
  };


  return (
    <div className={`mobile ${tab === "list" ? "active" : ""} col-lg-4 shadow full-height p-0`}>
      {setSuccessConversionNotification}
      <div className="p-2 d-flex justify-content-between">
        {/* <button className={`btn btn-custom btn-capsule flex-1 ml-1 mr-1 chat-docs ${hasJoined ? 'chat-active' : ''}`} onClick={showHideDocs}>{showDocuments ? 'Show Chats' : 'Show Documents'}</button> */}
        <button className={`btn btn-customWp btn-capsule flex-1 ml-1 mr-1 chat-docs ${hasJoined ? 'chat-active' : ''}`} onClick={() => { setInitializeWp(true) }}>Initiate a WhatsApp Conversation</button>
        <button className={`btn btn-custom btn-capsule flex-1 ml-1 mr-1 mobile-only ${hasJoined ? 'chat-active' : ''}`} onClick={() => { setTabs("chat") }}>Back to chat</button>
        <button className="header-icon ml-2 open-sidebar" onClick={showChatDetails}><FontAwesomeIcon icon={["fa", "chevron-left"]} /></button>
      </div>
      <Modal
        title='Initiate a WhatsApp Conversation'
        open={initializeWp}
        onCancel={() => setInitializeWp(false)}
        footer={[
          <Button
            style={{
              backgroundColor: '#2c42a3',
              color: '#ffff',
            }}
            onClick={() => {
              form.validateFields().then(() => { initiateConversation() })
            }}
          >
            Initiate
          </Button>,
          <Button
            onClick={() => {
              form.resetFields();
              setInitializeWp(false)
            }}
          >
            Cancel
          </Button>,
        ]}>
        <Form
          form={form}
          labelCol={{ span: 6 }}
          wrapperCol={{ span: 19 }}
          className='pt-2'
        >
          <Form.Item
            label='Enter a Number'
            name='number'
            className='font-600'
            rules={[
              {
                required: true,
                message: "Please Enter an Number!",
              },
              {
                pattern: /^[\d]{10}$/,
                message: "Phone Numbe should contain 10 number",
              },
            ]}
          >
            <Input value={wpNumber} prefix='+91' addonBefore={<UserOutlined />} onChange={(e) => setWpNumber(e.target.value)} className='ml-2 font-weight-normal' style={{ width: '60%' }} />
          </Form.Item>
          <Form.Item
            label='Select a Channel'
            name='channel      '
            className='font-600'
            rules={[
              {
                required: true,
                message: "Please select a Channel!",
              },
            ]}
          >
            <Select
              className='ml-2 font-weight-normal'
              style={{
                width: "60%",
              }}
              onChange={handleChannelChange}
              value={channelBot ? channelBot : null}
              options={[
                {
                  value: 'bot-37eb929a',
                  label: 'Nora',
                },
                {
                  value: '112bc24aeea5105893748_Dev',
                  label: 'Nora Support',
                },
              ]}
            />
          </Form.Item>
        </Form>
      </Modal>
      {/* {showDocuments ? (
                <DocumentList openDoc={openDoc}></DocumentList>
                ) : ( */}
      <div className={`${hasJoined ? 'disabled' : ''} chat-list-container`}>
        <div className="search-box">
          <FontAwesomeIcon icon={["fa", "search"]} />
          <input type="text" value={search} name="search" onChange={onChangeHandler} placeholder="Type in to search" />
        </div>
        <div className="d-flex justify-content-between show-chat m-2">
          {/* <a onClick={fetchConversationList} className={`${hasJoined ? 'disabled' : ''} ${showSavedChats ? '' : 'selected d-flex align-items-center'}`}>Recent chats <FontAwesomeIcon icon={["fa", "chevron-down"]} /></a> */}
          {/* <a onClick={fetchSavedChats} className={`${hasJoined ? 'disabled' : ''} ${showSavedChats ? 'selected' : ''}`}>Saved chats <FontAwesomeIcon icon={["fa", "chevron-down"]} /></a> */}
          <Select
            mode="multiple"
            style={{ width: '80%' }}
            placeholder="Select options"
            value={selectedValues}
            onChange={handleSelectChange}
            options={optionList}
          >

            {/* Add more Option components for additional options*/}
          </Select>
          {/* <Space direction='horizontal'>
                        <Switch checkedChildren="WhatsApp" unCheckedChildren="WhatsApp" defaultChecked />
                        <Switch checkedChildren="App" unCheckedChildren="App" defaultChecked />
                        <Switch checkedChildren="Web" unCheckedChildren="Web" defaultChecked />
                        <Switch checkedChildren="Support" unCheckedChildren="Support" defaultChecked />
                        </Space>
                        */}
        </div>
        <div className="conversation-container" >
          <ul>
            {conversations.length === 0 ? (
              <>
                <li><Skeleton active avatar paragraph={{ rows: 1 }} style={{ padding: 10 }} /></li>
                <li><Skeleton active avatar paragraph={{ rows: 1 }} style={{ padding: 10 }} /></li>
                <li><Skeleton active avatar paragraph={{ rows: 1 }} style={{ padding: 10 }} /></li>
                <li><Skeleton active avatar paragraph={{ rows: 1 }} style={{ padding: 10 }} /></li>
                <li><Skeleton active avatar paragraph={{ rows: 1 }} style={{ padding: 10 }} /></li>
                <li><Skeleton active avatar paragraph={{ rows: 1 }} style={{ padding: 10 }} /></li>
              </>
            ) : (
              <ul>{renderConversationsList(showSavedChats ? savedChatsList : conversations, conversation)}</ul>
            )}
          </ul>
        </div>
        {/* <div className="d-flex justify-content-center p-2">
                        {
                            // <button disabled={hasJoined || (showSavedChats && savedChatsList.length < 150) || (!showSavedChats && conversations.length < 150)} className="btn btn-custom btn-capsule" onClick={showMore}>Show previous chats</button>

                        }
                    </div> */}
      </div>
      {/* )} */}
    </div>
  )
};

export default ChatList;