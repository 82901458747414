import React, { useEffect, useState } from 'react';
import Header from '../Header';
import './boradcast.css';
import Sidebar from './sidebar'
import BroadcastHome from './broadcastHome';
import Share from './share';
import { getGroupListData} from './broadcastAPI'

const Broadcast = () => {
    const [selectedTab, setSelectedTab] = useState('group');
    const [selectedGroupId, setSelectedGroupId] = useState('');
    const [addShareContentType, setShareContentType] = useState('');
    const [groupList, setGroupList] = new useState([]);

    return (
        <React.Fragment>
            <Header></Header>
            <div className='container-fluid home'>
                <div className='row'>
                    <div className='col-md-3 shadow full-height'>
                        <Sidebar
                            setSelectedTab={setSelectedTab}
                            selectedTab={selectedTab}
                            selectedGroupId={setSelectedGroupId}
                            groupList = {groupList}
                            setGroupList = {setGroupList}
                        ></Sidebar>
                    </div>
                    {selectedTab.type === 'share' ?
                        (
                            <div className='col-md-2 shadow full-height '>
                                <Share
                                    setShareContentType={setShareContentType}
                                    selectedGroupId={selectedGroupId}
                                ></Share>
                            </div>
                        ) : ""}
                    <div className={`${selectedTab === 'share' ? "col-md-7" : "col"} `}>
                        <BroadcastHome
                            selectedTab={selectedTab}
                            addShareContentType={addShareContentType}
                            selectedGroupId={selectedGroupId}
                            setGroupList = {setGroupList}
                            setSelectedGroupId = {setSelectedGroupId}
                        ></BroadcastHome>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
};

export default Broadcast;