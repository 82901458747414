import React from "react";
import { library } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import shona from "../../assets/img/Polynomial Logo small.png";
import { faFilePdf } from '@fortawesome/free-solid-svg-icons';
import { isDesktop } from 'react-device-detect';
import {
  DownloadOutlined,
  RotateLeftOutlined,
  RotateRightOutlined,
  SwapOutlined,
  ZoomInOutlined,
  ZoomOutOutlined,
} from '@ant-design/icons';
import {
  faDownload,
  faPaperPlane,
  faMicrophone,
  faPaperclip,
  faFileImage,
  faFileAlt,
  faTimes,
  faChevronLeft,
  faListUl,
} from "@fortawesome/free-solid-svg-icons";
import { Image,Space } from "antd";
import './preview.css'

library.add(
  faDownload,
  faPaperPlane,
  faPaperclip,
  faMicrophone,
  faFileImage,
  faFileAlt,
  faTimes,
  faChevronLeft,
  faListUl
);
const Chat = (props) => {
  const {
    tab,
    conversation,
    hasJoined,
    showChatList,
    showDocumentsList,
    showChatDetails,
    chatArray,
    attachmentPanel,
    setAttachmentPanel,
    triggerUpload,
    imageInput,
    uploadAttachment,
    docInput,
    message,
    onChangeHandler,
    enterChangeHandler,
    sendMessage,
    extractPhoneNo,
  } = props;
  const phoneNo = extractPhoneNo(conversation.convId);
  const username = conversation.userData ? conversation.userData.name : null;
  console.log(
    "username value change called",
    conversation.userData,
    conversation
  );

  const renderThumbnail = (chat) => {
    const activity = chat;
    return (
      <a
        href={activity.value.attachments[0].contentUrl}
        download={activity.value.attachments[0].contentUrl}
        className="thumbnail"
        style={{ maxWidth: "140px" }}
      >
        <div className="thumbnail-header">{chat.text}</div>
        <div className="thumbnail-body">
          <div className="overlay">
            <div className="circle">
              <FontAwesomeIcon icon={["fa", "download"]} />
            </div>
          </div>
          {activity.value.attachments[0].contentType === "image/jpeg" ||
          activity.value.attachments[0].contentType === "image/png" ? (
            <img
              style={{ width: "100%" }}
              src={activity.value.attachments[0].contentUrl}
              alt={activity.value.attachments[0].name}
            />
          ) : (
            activity.value.attachments[0].contentType
          )}
        </div>
        <div className="thumbnail-footer">
          {activity.value.attachments[0].name}
        </div>
      </a>
    );
  };

  const renderChatArray = (chatArray) => {
    {
      console.log("renderChatArray", chatArray);
    }
    return chatArray.map((chat, key) => {
      // console.log(chat.timestamp)
      let timestamp = new Date(chat?.timestamp ? chat?.timestamp : Date.now());
      timestamp = timestamp.toLocaleString([], {
        hour: "numeric",
        minute: "numeric",
        hour24: true,
      });

      let text = chat?.text; // HTML content

      // Create a temporary element to extract text from HTML
      const tempElement = document.createElement("div");
      tempElement.innerHTML = text;
      const plainText = tempElement.innerText; // Extracted plain text

      return (
        <React.Fragment key={key}>
          {chat?.text != null &&
          chat?.text != undefined &&
          chat?.text !== "init" ? (
            chat?.value ? (
              chat?.value?.sender === "user" ? (
                chat?.value?.attachments &&
                chat?.value?.attachments?.length > 0 ? (
                  <div className="left">
                    {renderThumbnail(chat)}
                    <div>{timestamp}</div>
                  </div>
                ) : (
                  <div className="left">
                    {chat.text}
                    <div>{timestamp}</div>
                  </div>
                )
              ) : chat?.value?.sender === "agent" ? (
                chat?.value?.attachments ? (
                  <div className="right">
                    {renderThumbnail(chat)}
                    <div>{timestamp}</div>
                  </div>
                ) : (
                  <div className="right">
                    {chat?.text}
                    <div>{timestamp}</div>
                  </div>
                )
              ) : (
                <div className="right">
                  <img src={shona} className="avatar" alt="Shona" />
                  {plainText}
                  <div>{timestamp}</div>
                </div>
              )
            ) : (
              <div className="right">
                <img src={shona} className="avatar" alt="Shona" />
                {plainText}
                <div>{timestamp}</div>
              </div>
            )
          ) : chat?.type === "image/jpeg" || chat?.type === "image/png" || chat?.type === "image" && chat.sender === "user" ? (
            <div className="m-3 p-1" style={{backgroundColor:'#2c42a3', width:'fit-content'}} >
              <Image
                src={chat.url || chat.value.imageUrl}
                alt={chat.name}
                height={200}
                width={250} preview={{
                  toolbarRender: (
                    _,
                    {
                      transform: { scale },
                      actions: { onFlipY, onFlipX, onRotateLeft, onRotateRight, onZoomOut, onZoomIn },
                    },
                  ) => (
                    <Space size={12} className="toolbar-wrapper">
                      {/* <DownloadOutlined onClick={onDownload(chat?.url)} /> */}
                      <SwapOutlined rotate={90} onClick={onFlipY} />
                      <SwapOutlined onClick={onFlipX} />
                      <RotateLeftOutlined onClick={onRotateLeft} />
                      <RotateRightOutlined onClick={onRotateRight} />
                      <ZoomOutOutlined disabled={scale === 1} onClick={onZoomOut} />
                      <ZoomInOutlined disabled={scale === 50} onClick={onZoomIn} />
                    </Space>
                  ),
                }}
              ></Image>
            </div>
          )  : chat?.type === "image/jpeg" || chat?.type === "image/png" || chat?.type === "image"  ? (
            <div className="right m-3 p-1" style={{backgroundColor:'#2c42a3', width:'fit-content'}} >
            <Image
              src={chat.url || chat.value.imageUrl}
              alt={chat.name}
              height={200}
              width={250} preview={{
                toolbarRender: (
                  _,
                  {
                    transform: { scale },
                    actions: { onFlipY, onFlipX, onRotateLeft, onRotateRight, onZoomOut, onZoomIn },
                  },
                ) => (
                  <Space size={12} className="toolbar-wrapper">
                    {/* <DownloadOutlined onClick={onDownload(chat?.url)} /> */}
                    <SwapOutlined rotate={90} onClick={onFlipY} />
                    <SwapOutlined onClick={onFlipX} />
                    <RotateLeftOutlined onClick={onRotateLeft} />
                    <RotateRightOutlined onClick={onRotateRight} />
                    <ZoomOutOutlined disabled={scale === 1} onClick={onZoomOut} />
                    <ZoomInOutlined disabled={scale === 50} onClick={onZoomIn} />
                  </Space>
                ),
              }}
            ></Image>
          </div>
          ) : chat?.type === "video/mp4" ? (
            <div className="m-3">
              <video width={250} height={200} controls >
                <source src={chat.url} type="video/mp4" />
                {/* Add additional source tags for other video formats if desired */}
                Your browser does not support the video tag.
              </video>
              {/* <img src={chat.url} alt={chat.name} height={100} width={100}></img> */}
              {/* {chat?.value?.data?.length>0 ?(
                            <div div className="right">
                                {chat?.value?.data.map((items,index)=>{
                                    return(<div key={index}>
                                         <img src={items?.imageUrl} alt={items?.imageUrl}></img>
                                         <div>{items.text}</div>
                                         <div>{items.value}</div>
                                    </div>)
                                })}
                            </div>
                        ):null} */}
            </div>
          ) : 
          chat?.type === "application/pdf" && chat.sender === "user" ? (
            <div className="m-3">
             <a href={chat.url} target="_blank" rel="noopener noreferrer">
            <FontAwesomeIcon icon={faFilePdf} size="3x" />
             </a>
            </div>
          ) : 
          chat?.type === "application/pdf" && chat.sender === "agent" ? (
            <div className=" right m-3">
             <a href={chat.url} target="_blank" rel="noopener noreferrer">
            <FontAwesomeIcon icon={faFilePdf} size="3x" />
             </a>
            </div>
          ):
          null}
        </React.Fragment>
      );
    });
  };

  function removeNullElements(arr) {
    return arr.filter((element) => element !== null);
  }

  

  return (
    <div
      className={`mobile ${
        tab === "chat" ? "active" : ""
      } col-lg-5 full-height p-0 d-flex flex-column`}
    >
      <div className="chat-header d-flex align-items-center justify-content-center">
        {/* <div className="profile-image" style={{ width: 90 }}>
                                <img src={dp} alt="Profile picture" style={{ width: 65 }} />
                            </div> */}
        <div className="user-name">
          {console.log("username", username, "phone no", phoneNo)}
          <h5>
            <span>
              <strong>
                {username ? (
                  username
                ) : phoneNo ? (
                  phoneNo
                ) : (
                  <div className="font-theme">User</div>
                )}{" "}
              </strong>
            </span>
          </h5>
          <div className="email"></div>
        </div>
        <button
          className={`header-icon ml-auto ${hasJoined ? "disabled" : ""}`}
          onClick={showChatList}
        >
          <FontAwesomeIcon icon={["fa", "list-ul"]} />
        </button>
        <button className="header-icon ml-2" onClick={showDocumentsList}>
          <FontAwesomeIcon icon={["fa", "file-alt"]} />
        </button>
        <button
          className="header-icon ml-2 open-sidebar"
          onClick={showChatDetails}
        >
          <FontAwesomeIcon icon={["fa", "chevron-left"]} />
        </button>
      </div>
      <div className="body">
        <div className="chat pt-4" id="chat-container">
          {removeNullElements(chatArray).length > 0 ? (
            renderChatArray(removeNullElements(chatArray))
          ) : (
            <>
              {/* <div className="justify-content-center"> */}
              <img
                src="/noChats.svg"
                height={100}
                width={100}
                alt="No Chats"
                className="w-100 h-50"
              ></img>
              {/* </div> */}
              <div className="text-center font-600 fa-lg font-theme">
                Your chat will be visible here.
              </div>
            </>
          )}
        </div>
      </div>
      <div className="footer position-relative">
        {attachmentPanel ? (
          <div className="upload-file shadow">
            <h5>Upload the image of Document</h5>
            <div
              className="close-icon"
              onClick={() => {
                setAttachmentPanel(false);
              }}
            >
              <FontAwesomeIcon icon={["fa", "times"]} />
            </div>
            <div className="upload-icon-container">
              <div
                className={`upload-icon shadow ${!isDesktop && 'mobile-upload-icon'}`}
                onClick={() => {
                  triggerUpload("image");
                }}
              >
                <FontAwesomeIcon icon={["fa", "file-image"]} />
              </div>
              <div
                className={`upload-icon shadow ${!isDesktop && 'mobile-upload-icon'}`}
                onClick={() => {
                  triggerUpload("doc");
                }}
              >
                <FontAwesomeIcon icon={["fa", "file-alt"]} />
              </div>
              <input
                type="file"
                accept="image/*"
                ref={imageInput}
                style={{ display: "none" }}
                onChange={uploadAttachment}
              />
              <input
                type="file"
                accept=".xlsx,.xls,.doc,.docx,.txt,.pdf"
                ref={docInput}
                style={{ display: "none" }}
                onChange={uploadAttachment}
              />
            </div>
          </div>
        ) : null}
        <div className={`search-box ${!hasJoined ? "disabled" : null}`}>
          <input
            type="text"
            placeholder="Type here..."
            value={message}
            name="chat"
            onChange={onChangeHandler}
            onKeyDown={enterChangeHandler}
            autoComplete="off"
          />
          <FontAwesomeIcon icon={["fa", "paper-plane"]} className={`${ hasJoined && 'send-Icon'}`} onClick={sendMessage} />
          <FontAwesomeIcon
            icon={["fa", "paperclip"]}
            className={`${ hasJoined && 'send-Icon'}`}
            onClick={() => {
              setAttachmentPanel(true);
            }}
          />
          {/* <FontAwesomeIcon disabled icon={["fa", "microphone"]} /> */}
        </div>
      </div>
    </div>
  );
};

export default Chat;

