import { axiosInstance } from './../../interceptor';
import { NotificationContainer, NotificationManager } from 'react-notifications';

export const getGroupListData = async (queryParams) => {
    return axiosInstance
        .get(`/broadcast/fetch_groups`,{
            params: queryParams
        })
        .then((response) => {
            return response.data.data;
        })
        .catch((error) => {
            console.log("The error is", error);
            throw error;
        });
}

export const addAGroup = async (payload) => {
    return axiosInstance
        .post(`broadcast/add_group`, payload)
        .then((response) => {
            NotificationManager.success("Group added successfully");
            return response.data
        })
        .catch((error) => {
            NotificationManager.error("Something went wrong");
            console.log(error);
            throw error;
        })
}

export const getGroupDetailById = (groupId, page) => {
    return axiosInstance
        .get(`/broadcast/fetch_group/${groupId}/${page}`)
        .then((response) => {
            return response.data
        })
        .catch((error) => {
            console.log(error);
            throw error;
        })
}

export const deleteGroupById = (groupId) => {
    return axiosInstance
        .delete(`broadcast/delete_group/${groupId}`)
        .then((response) => {
            NotificationManager.success("Group deleted successfully");
            return response.data;
        })
        .catch((error) => {
            NotificationManager.error(error);
            console.log(error);
            throw error;
        })
}

export const updateGroupById = (groupId, payload) => {
    return axiosInstance
        .post(`broadcast/update_group/${groupId}`, payload)
        .then((response) => {
            NotificationManager.success("Updated successfully");
            return response.data;
        })
        .catch((error) => {
            console.log(error);
            throw error;
        })
}

export const getChannel = (queryParams) => {
    return axiosInstance
        .get(`broadcast/compose/get_channels`, {
            params: queryParams
        })
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            console.log(error);
            throw error;
        })
}

export const composeMessageForGroup = (groupId, payload) => {
    return axiosInstance
        .post(`/broadcast/compose/${groupId}`, payload)
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            console.log(error);
            throw error;
        })
}

export const getHistoryListByGroup = (groupId) => {
    return axiosInstance
        .get(`/broadcast/history/${groupId}`)
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            console.log(error);
            throw error;
        })
}