import React, { useEffect, useState } from 'react';
import './boradcast.css';
import { Modal } from 'react-responsive-modal';
import { getGroupListData, addAGroup } from './broadcastAPI'
import { HistoryOutlined, EditOutlined, SendOutlined } from '@ant-design/icons'


const Sidebar = (props) => {

    const botType = localStorage.getItem('botType')
    const [addGroup, setAddgroup] = useState(false);
    // add member popup
    const onAddGroupOpenModal = () => setAddgroup(true);
    const onAddGroupCloseModal = () => setAddgroup(false);
    const [groupData, setGroupData] = new useState({});
    // const [groupList, setGroupList] = new useState([]);
    // const {groupList, setGroupList} = props

    useEffect(() => {
        const queryParams = {
            botType: botType.split(",")
        }
        getGroupListData(queryParams).then((response) => {
            if (response?.length > 0) {
                props.setGroupList([...response]);
            } else {
                props.setGroupList([])
            }
        })
    }, [])

    const saveGroupData = () => {
        console.log(groupData, 'group Data');

        const reader = new FileReader();
        reader.readAsDataURL(groupData['file']);
        reader.onload = () => {
            const base64File = reader.result.split(',')[1];

            const payload = {
                "group_name": groupData['name'],
                "file": base64File,
                "botType": botType.split(",")
            };

            addAGroup(payload)
                .then((response) => {
                    console.log(response, 'response');
                    const queryParams = {
                         botType: botType.split(",")
                    }
                    getGroupListData(queryParams).then((response) => {
                          if (response?.length > 0) {
                          props.setGroupList([...response]);
                          } else {
                    props.setGroupList([])
                    }
                   })
                });
        };

        onAddGroupCloseModal();
        setGroupData({})
        const queryParams = {
            botType: botType.split(",")
        }
        getGroupListData(queryParams).then((response) => {
            if (response?.length > 0) {
                props.setGroupList([...response]);
            } else {
                props.setGroupList([])
            }
        })

    }


    const handleAddedGroupData = (event) => {
        groupData['name'] = event.target.value;
        setGroupData({ ...groupData })
    }

    const HandleEditHistoryShareClick = (type, groupId) => {
        switch (type) {
            case 'edit':
                props.setSelectedTab({ type: 'edit' });
                break;
            case 'history':
                props.setSelectedTab({ type: 'history' });
                break;
            case 'share':
                props.setSelectedTab({ type: 'share' });
                break;
        }
        props.selectedGroupId(groupId);
    }


    return (
        <div>
            <div>
                <div className='d-flex w-100 mt-2'>
                    {/* ${props.selectedTab === "group" ? "active" : ""} */}
                    <div className={`activeBroadcast nav-tab`}>Group</div>
                </div>
                <hr className='nav-hr'></hr>
            </div>
            {props.groupList?.length > 0 ?
                (<div>
                    {props.groupList.map((group) => (
                        <div className='mt-2' key={group.group_id}>
                            <div className='d-flex w-100 justify-content-between'>
                                <div className='group-title pointer' onClick={() => HandleEditHistoryShareClick('edit', group.group_id)}>{group.group_name}</div>
                                <div className='d-flex'>
                                    <span style={{ color: 'white', backgroundColor: '#636CDA', width: '25px', borderRadius: "4px", height: "25px" }} onClick={() => HandleEditHistoryShareClick('history', group.group_id)} className='pointer p-1 mx-2 d-flex justify-content-center align-items-center'><HistoryOutlined /></span>
                                    <span style={{ color: 'white', backgroundColor: '#636CDA', width: '25px', borderRadius: "4px", height: "25px" }} onClick={() => HandleEditHistoryShareClick('edit', group.group_id)} className='pointer p-1 mx-2 d-flex justify-content-center align-items-center'><EditOutlined /></span>
                                    <span style={{ color: 'white', backgroundColor: '#636CDA', width: '25px', borderRadius: "4px", height: "25px" }} onClick={() => HandleEditHistoryShareClick('share', group.group_id)} className='pointer p-1 mx-2 d-flex justify-content-center align-items-center'><SendOutlined /></span>
                                </div>
                            </div>
                            <hr className='m-0 nav-hr'></hr>
                        </div>
                    ))}
                </div>
                )
                :
                (<div className='no-group-wrapper'>
                    <span className='no-group-content'>There is no data to show. You can add data from the add button below.</span>
                </div>)
            }
            <div className='add-group' onClick={onAddGroupOpenModal}>
                <span className='add-group-span'>+</span>
            </div>

            <Modal open={addGroup} onClose={onAddGroupCloseModal} closeOnEsc={true} closeOnOverlayClick={false}
                showCloseIcon={false} center name='addMemberModel'>
                <div className="p-2">
                    <div className='fs-18 fw-900 text-center'>
                        UPLOAD CONTACT LIST
                    </div>
                    <div className='form-group mt-3'>
                        <label className='fs-14 fw-600'>Group Name</label>
                        <input className = 'input' type='text' name='groupName' onChange={(event) => handleAddedGroupData(event)}></input>
                    </div>
                    <div className='fs-14 fw-600'>
                        Add Member
                    </div>
                    <hr className='nav-hr mt-1 mb-2'></hr>
                    <div className='media-wrapper p-2'>
                        <label htmlFor="fileInput" className="browse-button mb-0">
                            Browse
                            <input className = 'input'
                                id="fileInput"
                                type="file"
                                name="media"
                                style={{
                                    display: 'none',
                                }}
                                // onChange={()=>handleFileChange(i)}
                                onChange={(event) => {
                                    const file = event.target.files[0]
                                    if (file) {
                                        groupData['file'] = file;
                                        groupData['fileName'] = file?.name;
                                        setGroupData({ ...groupData });
                                    }

                                }}
                            />
                        </label>
                        {groupData['fileName'] && <span className='fs-14 fw-500 mt-1'>{groupData?.fileName}</span>}
                    </div>
                    <span className='add-group-note'>Note: The excel sheet must contain two columns i.e.; "Name" and "Contact".</span>
                    <div className="p-2 d-flex align-items-center justify-content-center">
                        <button style={{ backgroundColor: "#1732A4", color: "white" }} className="btn flex-1 ml-1 mr-1"><a
                            href="/group_template.xlsx"
                            download="contact-template"
                            style={{ textDecoration: "none", color: "white" }}
                        >
                            Download Template
                        </a></button>
                    </div>
                    <hr className='nav-hr mt-3 mb-3'></hr>
                    <div className="p-2 d-flex justify-content-between">
                        <button className="btn btn-custom flex-1 ml-1 mr-1" onClick={() => onAddGroupCloseModal()}>Cancel</button>
                        <button className="btn btn-custom flex-1 ml-1 mr-1" onClick={() => saveGroupData()}>
                            Save
                        </button>
                    </div>
                </div>
            </Modal>
        </div>
    );
};

export default Sidebar;
