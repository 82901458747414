import React, { useEffect } from "react";
import logo from "../../assets/img/Polynomial Logo small.png";
import { useHistory } from "react-router-dom";
import { NotificationContainer, NotificationManager } from 'react-notifications';
import { apiEndPoint, handoffServer } from "../../config";
import axios from 'axios';
import './login.css'
import { isDesktop } from 'react-device-detect';

const Login = (props) => {
    let history = useHistory();
    const [emailValue, setEmail] = React.useState("");
    const [passwordValue, setPassword] = React.useState("");


    useEffect(() => {
        console.log(props);
        if (props.authed) {
            isDesktop ?
            history.push("/dashboard"):
            history.push("/conversation");
        }
    }, []);

    const login = (e) => {
        e.preventDefault();

        let payload = {
            email: emailValue,
            password: passwordValue
        }

        const apiUrl = `${handoffServer}/loginAgent `;
        axios.post(apiUrl, payload).then((data) => {

            console.log(data);
            let resp = data.data;

            if (resp.success) {
                localStorage.setItem("token", resp.token);
                localStorage.setItem("agentId", resp.agentId);
                localStorage.setItem("agentName", resp.agentName);
                localStorage.setItem("botType", resp.botType)
                let manager = false;
                if (resp.manager) {
                    manager = true;
                }
                localStorage.setItem("manager", manager);
                window.location.reload();
            }
        }).catch((error) => {
            console.log(error.response);
            NotificationManager.error('', error?.response?.data?.message);
        });
    }


    const handleInputChange = (e) => {
        if (e.target.name === 'email') {
            setEmail(e.target.value);
        } else if (e.target.name === 'password') {
            setPassword(e.target.value);
        }
    }


    return (
        <div>
            <section id="entry-page">
                <form onSubmit={login}>
                    <div className="text-center">
                        <img src={logo} style={{ width: 60, marginBottom: 10 }} alt="Polynomial AI" className="text-center" />
                        <h4>POLYNOMIAL.AI</h4>
                        <h2>AGENT INTERATION HUB</h2>
                    </div>
                    <fieldset>
                        <ul>
                            <li>
                                <label for="email">Email:</label>
                                <input type="email" id="email" required value={emailValue} onChange={handleInputChange} name="email" />
                            </li>
                            <li>
                                <label for="password">Password:</label>
                                <input type="password" id="password" required value={passwordValue} onChange={handleInputChange} name="password" />
                            </li>
                        </ul>
                    </fieldset>
                    <button>Submit</button>
                </form>
            </section>
            <NotificationContainer />
        </div>
        // <div className="container-fluid login-bg d-flex align-items-center justify-content-center" style={{ height: '100vh' }}>
        //     <div className="login-box">
        //         <div className="header text-center">
        //             <img src={logo} style={{ width: 60, marginBottom: 20 }} alt="Polynomial AI" />
        //             <h3 className="text-uppercase">Polynomial.AI</h3>
        //             <h3 className="font-600">Agent Interaction Hub</h3>
        //         </div>
        //         <form onSubmit={login}>
        //             <div className="form-group">
        //                 <label htmlFor="email">Email</label>
        //                 <i className="fa fa-envelope icon"></i>
        //                 <input type="email" name="email" value={emailValue} onChange={handleInputChange} className="form-control" id="email" required />
        //             </div>
        //             <div className="form-group">
        //                 <label htmlFor="pwd">Password</label>
        //                 <i className="fa fa-envelope icon"></i>
        //                 <input type="password" name="password" value={passwordValue} onChange={handleInputChange} className="form-control" id="pwd" required />
        //             </div>
        //             <div className="form-group text-center">
        //                 <button className="btn btn-custom">Login</button>
        //             </div>
        //         </form>
        //     </div>
        //     <NotificationContainer />
        // </div>
    )
};

export default Login;
