import React, { useEffect, useState, useRef } from 'react'
import Header from "../Header";
import ChatList from "./ChatList";
import Chat from "./Chat";
import ChatControls from "./ChatControls";
import { directlineURL, handoffServer } from "../../config";
import { io } from "socket.io-client";
import 'react-responsive-modal/styles.css';
import { Modal } from 'react-responsive-modal';
import { animateScroll } from "react-scroll";
import { NotificationContainer } from 'react-notifications';
import useSound from 'use-sound';
import Notification from "../../assets/sounds/notification.mp3";
let timeoutId;//used to cancel setTimeout
// let token;//conversation token
let user;//agent conversation ID
// let expires_in_ms;//conversation token expiry time in milliseconds
const Home = () => {

    let agentName = localStorage.getItem("agentName");
    let agentId = localStorage.getItem("agentId");

    let [isWatching, setIsWatching] = useState(false);//keep track of the watched chats. true: agent is watching a chat but can't send message, false: agent is neither watching chat not joined a chat 
    let [hasJoined, setHasJoined] = useState(false);//keep track of the joined chats. true: agent is connected to some user and can send messages, false: agent is not connected to any user
    const timeOut = 300000;//5 minutes
    let [savedChatsList, setSavedChatsList] = useState([]);
    let [conversation, setConversation] = useState({});
    let [search, setSearch] = useState('');
    let [message, setMessage] = useState('');
    let [phoneNo, setPhoneNo] = useState('Not Available');
    let [online, setOnline] = useState(localStorage.getItem("online") === 'true' ? true : false);
    let [userConvId, setUserConvId] = useState(null);
    let [convBottype, setconvBottype] = useState(null)
    let [chatArray, setChatArray] = useState([]);
    let [manager, setManager] = useState([]);
    let [ipAddress, setIpAddress] = useState("Not Available")
    // let [nameOfUser,setNameofUser] = useState("Not Available")
    // let [emailOfUser,setEmailofUser] = useState("Not Available")
    let [isOldChat, setIsOldChat] = useState(false);
    let [showDocuments, setShowDocuments] = useState(false);
    let [attachmentPanel, setAttachmentPanel] = useState(false);
    let [reqAgentConvId, setreqAgentConvId] = useState(null);//requesting agent conversation id
    let [tab, setTabs] = useState("list");//tabs for mobile screen."list": chat list screen ,"chat": Single chat screen
    let [chatDetails, setChatDetails] = useState(false);//sidebar for mobile screen: true: show chat details sidebar, false: hide chat details sidebar
    const [conversations, setConversations] = useState([]);
    const [ConversationList, setConversationList] = useState([]);
    let [showSavedChats, setShowSavedChats] = useState(false);
    let [timer, setTimer] = useState('');
    let ref = useRef();
    let leaveChatRef = useRef();
    const [initializeWp, setInitializeWp] = useState(false);
    const [playNotification] = useSound(Notification);


    //Time out
    const botType = localStorage.getItem("botType");

    const [open, setOpen] = useState(false);
    const onOpenModal = () => setOpen(true);
    const onCloseModal = () => setOpen(false);
    const imageInput = useRef(null)
    const docInput = useRef(null)
    const endpoint = "https://intelligence.polynomial.ai"
    useEffect(() => {
        console.log({ botType })
        // if(!global.socket1){
        global.socket1 = io(endpoint, { query: { type: 'client', agentId: agentId, botType: botType, agentName: agentName }, path: '/handoff/socket.io' })
        // global.socket1=io("http://localhost:5000",{query: {type: 'client',agentId:agentId,botType:botType,agentName:agentName}})
        // }
        global.socket1.emit("howdy", "hello from human-handoff-client")
        console.log("socketio client", global.socket1)
        // generateToken();
        const manager = JSON.parse(localStorage.getItem("manager"));
        setManager(manager);//if true: this agent is manager otherwise not
        //set agent status based on stored flag. Used to retain status even if page refreshed
        global.socket1.on("endChat", () => {
            leaveChatRef.current.click();
        })
        global.socket1.on("updateConversationList", data => {
            console.log("updating.....", data);
            setConversationList(data);
            setShowSavedChats(false);
        })
        global.socket1.on('getWatchConversation', ({ activities, ipAddressConv }) => {
            console.log("activities", activities);
            if (ipAddressConv) {
                setIpAddress(ipAddressConv)
            }
            setChatArray(chatArray => [...chatArray, ...activities]);

            scrollToBottom();
        })
        global.socket1.on("leaveChat", () => {
            leaveChat();
        })
        //Socket event 'notification' looks for chat notification by any user or a non manager agent
        global.socket1.on("enableChat", (conversation) => {
            selectChat(conversation)
            setHasJoined(true);
        })
        global.socket1.on("notifications", data => {
            console.log({ data });
            onOpenModal();
            setreqAgentConvId(data.convId)
            const timerId = setTimeout(() => {
                ref.current.click();
            }, 3000);
            setTimer(timerId);
        });
        global.socket1.on("scheduleLeaveChat", () => {
            leaveChat()
        })
    }, [])

    useEffect(() => {
        if (conversations.length === 0) {
            setConversations(ConversationList);
        }
        else {
            const combinedData = [...conversations];
            ConversationList.forEach((obj) => {
                const index = combinedData.findIndex((item) => item.convId === obj.convId);
                if (index !== -1) {
                    combinedData[index] = { ...combinedData[index], ...obj };
                } else {
                    combinedData.push(obj);
                }
            });
            setConversations(combinedData);
            playNotification();
        }
    }, [ConversationList]);


    console.log('app', conversations.length)

    const setAgentOnline = (status) => {

        setOnline(status);
        console.log("******", status);
        localStorage.setItem("online", status);
        if (status) {
            console.log("enqueAgent")
            global.socket1.emit("enqueueAgent", { agentId, manager });

        } else {
            console.log("dequeueAgent")
            global.socket1.emit("dequeueAgent", agentId)

        }

    }

    const fetchConversationList = (e, timestamp) => {
    }

    // const fetchSavedChats = (e, timestamp) => {
    //     let apiUrl = `${apiEndPoint}/api/getsavedchats?agentId=${agentId}`;
    //     if (timestamp) {
    //         //if you want to fetch past saved chat list then pass timestamp of the last chat of current list
    //         //150 chats are fetched at once
    //         apiUrl = `${apiEndPoint}/api/getsavedchats?timestamp=${timestamp}&agentId=${agentId}`;
    //     }
    //     axios.get(apiUrl).then((data) => {
    //         setSavedChatsList(data.data);
    //         setShowSavedChats(true);
    //     }).catch((error) => {
    //         console.log(error.response);
    //     });
    // }

    // const deleteSavedChat = (e, conversation) => {

    //     let apiUrl = `${apiEndPoint}/api/deletesavedchat`;

    //     const payload = {
    //         agentId: agentId,
    //         userId: conversation.userId
    //     }

    //     axios.delete(apiUrl, { data: payload, headers: { Authorization: `Bearer ${token}` } }).then((data) => {
    //         console.log(data);
    //         NotificationManager.success('', "Removed from saved chat");
    //         fetchSavedChats();
    //     }).catch((error) => {
    //         console.log(error);
    //     });
    //     e.stopPropagation();
    // }


    const scrollToBottom = () => {
        animateScroll.scrollToBottom({
            containerId: "chat-container"
        });
    }

    const leaveChat = () => {

        global.socket1.emit("leaveConversation", {
            botType,
            manager,
            value: {
                sender: "agent",
                name: agentName
            },
        })
        setHasJoined(false);
    }

    const findChat = () => {
        //find chat for which notification is accepted, in conversations list and highlight it
        let conversation = conversations.find(conv => conv.convId === userConvId);
        selectChat(conversation, "join");
        console.log("Adding a check here at findChat")
    }

    const selectChat = (conversation, type) => {
        console.log("type", type, "conversation", conversation);
        const { convId, userId, botType } = conversation;
        console.log("Heyyyy", botType)
        if (type) {
            setChatArray([]);//Empty chat array
        }
        setConversation(conversation);
        setUserConvId(convId);
        //if it is an old chat possibly a few hours old then don't show input box
        const isOldChat = checkIsOldChat(conversation.timestamp);
        setIsOldChat(isOldChat);
        if (type === "watch") {
            setconvBottype(botType);
            //watch only when selecting an existing conversation
            //don't call it when accepting request for new chat
            //otherwise both watch and join will be called and it will show multiple instances of chat
            watchConversation(convId, userId, botType);
        }
        setTabs("chat");//for mobile view. Show single chat screen
        setPhoneNo("Not Available");//reset phone number
    }

    const acceptChat = () => {
        clearTimeout(timer);
        setUserConvId(reqAgentConvId);
        console.log(userConvId, reqAgentConvId);
        setChatArray([]);   //Empty chat array
        setConversation({});//remove any selected chat from list
        global.socket1.emit('watchConversation', { query: { conversationId: reqAgentConvId } });
        joinConversation(reqAgentConvId);
        onCloseModal();
        findChat();

    }



    const declineChat = () => {
        clearTimeout(timer);
        console.log("*****", reqAgentConvId, agentId)
        global.socket1.emit('declineAgent', { agentId: agentId, conversationId: reqAgentConvId, manager });
        onCloseModal();
    }

    // const setAgentInactive = () => {
    //     const apiUrl = `${directlineURL}/v3/directline/conversations/${agentConvIdVar}/activities`;
    //     const payload = {
    //         type: "event",
    //         name: 'agent/inactive',
    //         value: {}
    //     };
    //     console.log(token);
    //     axios.post(apiUrl, payload, { headers: { Authorization: `Bearer ${token}` } }).then((data) => {
    //         console.log(data);
    //         setAgentOnline(false);
    //     }).catch((error) => {
    //         console.log(error);
    //     });
    // }

    const uploadAttachment = (e) => {
        let files = e.target.files;
        if (files.length > 0) {
            let file = files[0];
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onloadend = () => {
                // The result contains the Base64-encoded string
                const base64 = reader.result;
                if(base64){
                    try {
                        const fileType = file?.type?.split('/');
                        global.socket1.emit('sendMessage', {
                            conversationId: conversation.convId,
                            agentId: agentId,
                            message: null,
                            hasMedia :true,
                            mediaFile:base64,
                            mediaType :fileType[0]==='application'?'document':fileType[0], 
                            manager,
                            botType: convBottype,
                            value: {
                                sender: "agent",
                                name: agentName
                            },
                        })
            
                        console.log("Sent");
                        setAttachmentPanel(false);
                        setHasJoined(true);
                    } catch (err) {
                        console.log("error in sending message", err)
                    }
                }
            };

            // console.log('form data', formData)
            // const headers = {
            //     headers: {
            //         Authorization: "Bearer qP0qsYX9_Ho.PGotiqdrD_L2wPddgXe7Ym1lgW6y0XZZTdMN7PGZwxM"
            //     }
            // }
            // const apiUrl = `${botMngmtAPIURL}/upload`;
            // axios.post(apiUrl, formData, headers).then((data) => {
            //     sendAttachment(data.data);
            //     setAttachmentPanel(false);
            // }).catch((error) => {
            //     console.log(error);
            // });
        }
    }

    //Generate token which will be used to get websocket streamURL and another token
    //which will allow user to connect to chat stream


    // const refreshConvToken = () => {
    //     const apiUrl = `${directlineURL}/v3/directline/tokens/refresh`;
    //     const payload = {};
    //     axios.post(apiUrl, payload, { headers: { Authorization: `Bearer ${token}` } }).then((data) => {
    //         token = data.data.token;
    //         let { expires_in } = data.data;
    //         expires_in = expires_in - 300;//refresh token 5 minutes before expiry
    //         expires_in_ms = expires_in * 1000;
    //         setTimeout(() => {
    //             refreshConvToken();
    //         }, expires_in_ms);
    //     }).catch((error) => {
    //         console.log(error);
    //     });
    // }

    //extract phone number from chat
    //match for anything that looks like a phone number and display in chat details
    const extractPhoneNo = (text) => {
        if (text) {
            let regExp = /(?:[-+() ]*\d){10,13}/gm;
            let result = text.match(regExp);
            if (result) {
                result = result.map(function (s) { return s.trim(); });
                let formattedNumber = "+91 " + result[0].slice(2);
                return formattedNumber;
            }
            return false;
        }
        return false;
    }
    const phoneNumber = extractPhoneNo(conversation.convId);
    const nameOfUser = conversation.userData ? conversation.userData.name : null;
    const emailOfUser = conversation.userData ? conversation.userData.email : null;


    const joinConversation = (joinConvId) => {
        if (!joinConvId) {
            joinConvId = userConvId;
        }
        console.log("Adding above emit", convBottype)
        global.socket1.emit('joinConversation', {
            conversationId: joinConvId,
            agentId: agentId,
            message: manager ? `Manager ${agentName} joined the conversation` : `Our Champion ${agentName} joined the conversation`,
            manager,
            botType: convBottype,
            value: {
                sender: "agent",
                name: agentName
            },
        })

        setconvBottype(convBottype)
        // setHasJoined(true);

    }

    const watchConversation = (conv_id, user_id, botType) => {
        // const apiUrl = `${directlineURL}/v3/directline/conversations/${agentConvIdVar}/activities`;
        // const payload = {
        //     "type": "event",
        //     name: 'agent/watch',
        //     value: {
        //         convId: conv_id,
        //         userId: user_id
        //     }
        // };
        // axios.post(apiUrl, payload, { headers: { Authorization: `Bearer ${token}` } }).then((data) => {
        //     setIsWatching(true);
        // }).catch((error) => {
        //     console.log(error);
        // });
        // const apiUrl = `${directlineURL}/v3/directline/conversations/${agentConvIdVar}/activities`;

        global.socket1.emit('watchConversation', { query: { conversationId: conv_id, botType: botType } });


        setIsWatching(true);

    }

    //send chat on press of enter key
    const enterChangeHandler = (e) => {
        if (e.keyCode === 13) {
            sendMessage();
        }
    }

    const showChatList = () => {
        setShowDocuments(false);
        setTabs("list");
    }

    const showDocumentsList = () => {
        setTabs("list");
        setShowDocuments(true);
    }

    const sendMessage = (text) => {
        //if string is passed used that otherwise use 'message' variable set from input field
        console.log("sendMessage", text)
        let messageString = "";
        if (text) {
            messageString = message;
        } else {
            messageString = message;
        }
        try {
            console.log(convBottype)
            global.socket1.emit('sendMessage', {
                conversationId: conversation.convId,
                agentId: agentId,
                message: messageString,
                manager,
                botType: convBottype,
                value: {
                    sender: "agent",
                    name: agentName
                },
            })

            console.log("Sent")
            setHasJoined(true);
            setMessage("");
        } catch (err) {
            console.log("error in sending message", err)
        }

    }

    const getNameFromURL = (url) => {
        let array = url.split("/");
        if (array.length > 0) {
            return array[array.length - 1];
        } else {
            return "";
        }
    }

    // const sendAttachment = (attachment) => {

    //     const apiUrl = `${directlineURL}/v3/directline/conversations/${agentConvId}/activities`;
    //     const payload = {
    //         locale: "en-EN",
    //         type: "message",
    //         from: {
    //             id: agentId
    //         },
    //         text: "Attachment",
    //         value: {
    //             sender: "agent",
    //             name: agentName,
    //             attachments: [{
    //                 name: getNameFromURL(attachment.url),
    //                 contentType: attachment.type,
    //                 contentUrl: attachment.url
    //             }],
    //         }
    //     }

    //     axios.post(apiUrl, payload, { headers: { Authorization: `Bearer ${token}` } }).then((data) => {
    //         console.log(data);
    //     }).catch((error) => {
    //         console.log(error);
    //     });
    // }



    //Mobile view - open sidebar to show chat details
    const showChatDetails = () => {
        setChatDetails(true);
    }
    //Mobile view - close sidebar to that shows chat details
    const hideChatDetails = () => {
        setChatDetails(false);
    }


    const checkIsOldChat = (timestamp) => {
        //check if chat is more than 12 hours old
        const duration = 1000 * 60 * 60 * 12;
        const timeAgo = Date.now() - duration;
        return timestamp < timeAgo;
    }

    // const saveChat = () => {

    //     let apiUrl = `${apiEndPoint}/api/savechat`;
    //     const { userId, convId, lastTranscript, status, name, timestamp } = conversation;

    //     let payload = {
    //         userId: userId,
    //         convId: convId,
    //         lastTranscript: lastTranscript,
    //         status: status,
    //         name: name,
    //         timestamp: timestamp,
    //         agentId: agentId,
    //     };

    //     axios.post(apiUrl, payload, { headers: { Authorization: `Bearer ${token}` } }).then((data) => {
    //         console.log(data);
    //         NotificationManager.success('', data.data);
    //     }).catch((error) => {
    //         console.log(error);
    //     });
    // }

    const onChangeHandler = (e) => {
        const { name, value } = e.target;
        if (name === "search") {
            search = setSearch(value);
        } else if (name === "chat") {
            // clearTimeout(timeoutId);
            // timeoutId = setTimeout(() => {
            //     setAgentInactive();
            // }, timeOut);
            setMessage(value);
            console.log(message)
        }
    }


    const escalateToManager = () => {
        console.log(agentName)
        global.socket1.emit("escalateToManager", {
            name: agentName
        })
    }

    const showMore = (e) => {
        //note:e is not being used. It is just to fill up first argument
        if (conversations.length > 0) {
            const timestamp = conversations[conversations.length - 1].timestamp;
            fetchConversationList(e, timestamp);
        } else {
            fetchConversationList(e);
        }
    }

    const triggerUpload = (type) => {
        if (type === "image") {
            imageInput.current.click();
        } else if (type === "doc") {
            docInput.current.click();
        }
    }

    const switchChangeHandler = (e) => {
        setAgentOnline(e.target.checked);
    }

    const showHideDocs = () => {
        setShowDocuments(!showDocuments);
    }

    return (
        <React.Fragment>
            <Header online={online} hasJoined={hasJoined}></Header>
            <div className="container-fluid home" style={{
                width: '100%',
                paddingLeft: 15,
                paddingRight: 15,
                marginRight: 'auto',
                marginLeft: 'auto',
            }}>
                <div className="row">
                    <ChatList
                        tab={tab}
                        search={search}
                        hasJoined={hasJoined}
                        showHideDocs={showHideDocs}
                        showChatDetails={showChatDetails}
                        onChangeHandler={onChangeHandler}
                        // fetchSavedChats={fetchSavedChats}
                        savedChatsList={savedChatsList}
                        showDocuments={showDocuments}
                        setInitializeWp={setInitializeWp}
                        initializeWp={initializeWp}
                        setTabs={setTabs}
                        showMore={showMore}
                        selectChat={selectChat}
                        // deleteSavedChat={deleteSavedChat}
                        conversations={conversations}
                        conversation={conversation}
                        showSavedChats={showSavedChats}
                        fetchConversationList={fetchConversationList}
                        extractPhoneNo={extractPhoneNo}>
                    </ChatList>
                    <Chat
                        tab={tab}
                        conversation={conversation}
                        hasJoined={hasJoined}
                        showChatList={showChatList}
                        showDocumentsList={showDocumentsList}
                        showChatDetails={showChatDetails}
                        chatArray={chatArray}
                        attachmentPanel={attachmentPanel}
                        setAttachmentPanel={setAttachmentPanel}
                        triggerUpload={triggerUpload}
                        imageInput={imageInput}
                        uploadAttachment={uploadAttachment}
                        docInput={docInput}
                        message={message}
                        onChangeHandler={onChangeHandler}
                        enterChangeHandler={enterChangeHandler}
                        sendMessage={sendMessage}
                        extractPhoneNo={extractPhoneNo}
                    ></Chat>
                    <ChatControls
                        leaveChatRef={leaveChatRef}
                        chatDetails={chatDetails}
                        hideChatDetails={hideChatDetails}
                        online={online}
                        phoneNumber={phoneNumber}
                        nameOfUser={nameOfUser}
                        emailOfUser={emailOfUser}
                        ipAddress={ipAddress}
                        joinConversation={joinConversation}
                        isWatching={isWatching}
                        hasJoined={hasJoined}
                        manager={manager}
                        escalateToManager={escalateToManager}
                        // saveChat={saveChat}
                        leaveChat={leaveChat}
                        isOldChat={isOldChat}
                        switchChangeHandler={switchChangeHandler}
                        convID = {conversation.convId}>
                    </ChatControls>
                </div>
            </div>
            <Modal open={open} onClose={onCloseModal} closeOnEsc={false} closeOnOverlayClick={false} showCloseIcon={false} center>
                <div className="p-3">
                    <h4>New chat request</h4>
                    <div className="p-2 d-flex justify-content-between">
                        <button className="btn btn-custom btn-capsule flex-1 ml-1 mr-1" onClick={acceptChat}>Accept</button>
                        <button className="btn btn-custom btn-capsule flex-1 ml-1 mr-1" onClick={declineChat} ref={ref}>Decline</button>
                    </div>
                </div>

            </Modal>
            <NotificationContainer></NotificationContainer>
        </React.Fragment>

    )
};

export default Home;