import React, { useState } from 'react';
import { axiosInstance } from '../../interceptor';
import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { NotificationManager } from 'react-notifications';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import 'react-quill/dist/quill.core.css';
import 'react-quill/dist/quill.bubble.css';
import { useEffect } from 'react';
import parse from 'html-react-parser';
library.add(faChevronRight);
const ChatControls = (props) => {

    const { chatDetails, leaveChatRef, hideChatDetails, ipAddress, online, switchChangeHandler, isOldChat, phoneNumber, joinConversation, isWatching, hasJoined, manager, escalateToManager, saveChat, leaveChat, nameOfUser, emailOfUser, convID } = props;
    const [note, setNote] = useState("")
    const [takeNotes, setTakeNotes] = useState(true)
    const [noteHistory, setNoteHistory] = useState([])

    const getNotes = async (convID) => {
        const params = {
            conversationID: convID
        };
    
        console.log("here are the params", params)
        try {
            const response = await axiosInstance.get('notes/get-notes', {
                params: params
            });
            console.log(response)
            setNoteHistory(response.data.notes);
        } catch (error) {
            console.log(error);
        }
    };
    
    useEffect(() => {
        getNotes(convID, setNoteHistory);
    }, [convID]);


    const onCreateNote = async () => {
        const payload = {
            conversationID: convID,
            note: note
        }
        return axiosInstance
            .post(`notes/create-note`, payload)
            .then((response) => {
                NotificationManager.success("Note created successfully");
                setNote("")
                getNotes(convID)
                return response.data
            })
            .catch((error) => {
                NotificationManager.error("Something went wrong");
                console.log(error);
                setNote("")
                throw error;
            })
    }

    const formatDate = (dateString) => {
        const date = new Date(dateString);
        const day = date.getDate();
        const month = date.getMonth() + 1;
        const year = date.getFullYear() % 100;
        const formattedDate = `${day}/${month}/${year}`;
        return formattedDate
    }

    return (
        <div className={`mobile chat-details ${chatDetails ? 'open' : ''} col-lg-3 shadow full-height  d-flex flex-column p-0`}>
            <button onClick={hideChatDetails} className="close-sidebar"><FontAwesomeIcon icon={["fa", "chevron-right"]} /></button>
            <div className="basic-details d-flex flex-column justify-content-center align-items-center">
                <label className="switch">
                    <input type="checkbox" checked={online} onChange={switchChangeHandler} />
                    <div className="slider round">
                        <span className="on">Online</span>
                        <span className="off">Offline</span>
                    </div>
                </label>
            </div>
            <div className="personal-info">
                <h5>Personal info</h5>
                <ul>
                    <li>
                        <span className="title">Name</span>
                        <span style={{ "color": "#9b9b9b" }}>{nameOfUser ? nameOfUser : "Not available"}</span>
                    </li>
                    <li>
                        <span className="title">Phone no.</span>
                        <span style={{ "color": "#9b9b9b" }}>{phoneNumber ? phoneNumber : "Not available"}</span>
                    </li>
                    <li>
                        <span className="title">Email Address</span>
                        <span style={{ "color": "#9b9b9b" }}>{emailOfUser ? emailOfUser : "Not available"}</span>
                    </li>
                    {/* <li>
                        <span className="title">IP address</span>
                        <span style={{ "color": "#9b9b9b" }}>{ipAddress}</span>
                    </li> */}
                </ul>
            </div>
            <div className="container my-2 mb-4">
            <h5 className='text-center mb-3'>Actions</h5>
                <div className="row mx-2 my-2">
                    <div className="col-md-6 col-lg-6">
                        <button className="btn btn-custom p-1 mx-2 my-1" onClick={() => { joinConversation() }} disabled={!isWatching || hasJoined || isOldChat || !online}>Join conversation</button>
                    </div>
                    <div className="col-md-6 col-lg-6">
                        {!manager && (
                            <button className="btn btn-custom p-1 mx-2 my-1" onClick={escalateToManager} disabled={!hasJoined}>Escalate Chat</button>
                        )}
                    </div>
                </div>
                <div className="row mx-2">
                    <div className="col-md-6 col-lg-6">
                        <button className="btn btn-custom p-1 mx-2 my-1" onClick={leaveChat} disabled={!hasJoined} ref={leaveChatRef}>Leave chat</button>
                    </div>
                    <div className="col-md-6 col-lg-6">
                        <button className="btn btn-custom p-1 mx-2 my-1" disabled>Conversation History</button>
                    </div>
                </div>
            </div>
            {/* <div className="chat-controls d-flex justify-content-center">
                <ul>
                    <li>Actions</li>
                    <li className="button-container">
                        <button className="btn btn-custom p-1 mx-2" onClick={() => { joinConversation() }} disabled={!isWatching || hasJoined || isOldChat || !online}>Join conversation</button>
                        {!manager && (
                            <button className="btn btn-custom p-1 mx-2" onClick={escalateToManager} disabled={!hasJoined}>Escalate Chat</button>
                        )}
                    </li>
                    <li className="button-container">
                        <button className="btn btn-custom p-1 mx-2" onClick={leaveChat} disabled={!hasJoined} ref={leaveChatRef}>Leave chat</button>
                        <button className="btn btn-custom p-1 mx-2" disabled>Conversation History</button>
                    </li>
                </ul>
            </div> */}
            {isWatching?(  <div className='container p-2' style={{ height: "290px", backgroundColor: "#FEFED8", borderRadius: "19px 19px 0 0" }}>
                <h5 className='text-center mb-2'>Notes</h5>
                {takeNotes ? (
                    <>
                        <ReactQuill placeholder='Type your notes here....' style={{ backgroundColor: "white", height: "230px", borderRadius: '19px 19px 0 0', border: "0px", overflowY: "hidden" }} className='m-0' theme='bubble' value={note} onChange={setNote} />
                        <hr className='mt-0 mb-0 featurette-divider' style={{ backgroundColor: "#ccccccc7" }}></hr>
                        <div className='container d-flex justify-content-between mt-0 mb-2' style={{ backgroundColor: "white", borderRadius: '0 0 19px 19px' }}>
                            <div style={{ fontSize: '8px' }}>420/500</div>
                            <div><button className='btn btn-custom' style={{ padding: '0', width: '90px', backgroundColor: "#2c42a3", color: "white" }} onClick={() => setTakeNotes(false)}>History</button></div>
                            <div><FontAwesomeIcon icon={["fa", "paper-plane"]} className='pointer send-Icon' onClick={() => onCreateNote()} /></div>
                        </div>
                    </>
                ) :
                    <div className='container'>
                        <div className='mb-4' style={{ overflowY: "auto", height: "200px" }}>
                            {noteHistory.map((note) => {
                                return (
                                    <>
                                        <div className='p-2 my-2 mx-0' style={{ backgroundColor: "white", borderRadius: "16px", fontSize: "13px" }} key={note.id}>
                                            {parse(note.note)}
                                            <div style={{ fontSize: "10px", borderRadius: '16px', color: 'gray' }} className="mt-0 mb-0 text-right">
                                                {formatDate(note.date)}
                                            </div>
                                        </div>
                                    </>
                                )
                            })}
                        </div>
                        <div className="text-center"><button className='btn btn-custom' style={{ padding: '0', width: '90px', backgroundColor: "#2c42a3", color: "white" }} onClick={() => setTakeNotes(true)}>Take Notes</button></div>
                    </div>}

            </div>):null}
          

        </div>
    )
};

export default ChatControls;