import React from 'react';
import { useState } from 'react';
import { Modal } from 'react-responsive-modal';
import { composeMessageForGroup } from './broadcastAPI';
import { NotificationContainer, NotificationManager } from 'react-notifications';

const Share = (props) => {
    const [showTextModal, setShowTextModal] = useState(false)
    const [text, setText] = useState("")
    const onDeleteCloseModal = () => {
        setShowTextModal(false)
    }
    return (
        <div>
            <div className='mt-3'>
                Elements
            </div>
            <hr className='mt-1 nav-hr'></hr>
            <div className='d-flex justify-content-between w-100 mt-3'>
                <div className='share-title'>Media</div>
                <div className='pr-2 add-share' onClick={() => props.setShareContentType({ type: 'media' })}>+</div>
            </div>
            <hr className='mt-1 nav-hr'></hr>
            <div className='d-flex justify-content-between w-100 mt-3'>
                <div className='share-title'>Text Message</div>
                <div className='pr-2 add-share' onClick={() => { props.setShareContentType({ type: 'message' }); setShowTextModal(true); }}>+</div>
            </div>
            <hr className='mt-1 nav-hr'></hr>
            {/* <Modal open={showTextModal} onClose={onDeleteCloseModal} closeOnEsc={true} closeOnOverlayClick={false}
                showCloseIcon={false} center>
                <div className="p-2">
                    <span className='fw-900'>Compose your message here</span>
                    <hr className='nav-hr mt-3 mb-3'></hr>
                    <div>
                        <textarea className='w-100' rows={4} name='textMessage' onChange={(e) => {
                            setText(e.target.value)
                        }}></textarea>
                    </div>
                    <div className="p-2 d-flex justify-content-between">
                        <button className="btn btn-custom flex-1 ml-1 mr-1" onClick={() => onDeleteCloseModal()}>Cancel</button>
                        <button className="btn btn-custom flex-1 ml-1 mr-1" onClick={() => sendMessage(text)}>Send</button>
                    </div>
                </div>
            </Modal>
            <NotificationContainer/> */}
        </div>
    );
};

export default Share;