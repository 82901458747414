import axios from "axios";
import { startLoading, endLoading } from './actions';
import {
  NotificationManager,
} from "react-notifications";
import { handoffServer } from "./config";
export const axiosInstance = axios.create({
  baseURL: handoffServer,
  // timeout: 600000,
});

const interceptor = {
  setup: (history) => {
    axiosInstance.interceptors.request.use(req => {
      startLoading();
      const accessToken = localStorage.getItem("token");
      let headers;
      if (accessToken) {
        headers = {
          Authorization: `Bearer ${accessToken}`,
          // "Content-Type": `application/json`,
        };
      } else {
        headers = {
          "Content-Type": "application/json",
        };
      }
      return { ...req, headers };
    });

    axiosInstance.interceptors.response.use(res => {
      endLoading();
      return res;
    },
      (error) => {
        console.log(error.response);
        if (error.response.status === 401) {
          //Unauthorized
          localStorage.clear();
          history.push(`/`);
          window.location.reload(false);
        } else if (error.response.status === 400) {
          //Resource not found
        } else if (error.response.status === 500) {
          //Something went wront
          NotificationManager.error("Please try again after some time", "Something went wrong", 4000);
        }
        //console.clear();
        return Promise.reject(error);
      });
  },
};

export default interceptor;